import axios from "axios";

export const storeNotifToken = async data => {
    try{
const res = await axios.post(`/admin/notif`, data)
    return res.data
    }
    catch(err){
        return {
            error:true,
            msg:err
        }
    }
}