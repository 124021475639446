import {
  SET_PRODUCTS,
  ADD_ITEM,
  REMOVE_ITEM,
  CHANGE_COUNT,
  SET_DELIVERY,
  EMPTY_CART,
  SET_CART
} from "../actions/actionTypes";

const initialState = {
  allProducts: [],
  items: [],
  total: 0,
  deliveryFee:0,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCTS:
      return {
        ...state,
        allProducts: action.payload,
      };
      break;
    case ADD_ITEM:
      if (state.items.length < 1) {
        return {
          ...state,
          items: [action.payload],
          total: action.payload.product.product_price * action.payload.quantity,
        };
      } else {
        let currentItem = state.items.find(
          (item) => item.id == action.payload.id
        );
        if (currentItem) {
          let fiteredItems = state.items.filter(
            (item) => item.id != action.payload.id
          );
          return {
            ...state,
            items: [
              { ...currentItem, quantity: currentItem.quantity + 1 },
              ...fiteredItems,
            ],
            total:
              state.total +
              action.payload.product.product_price * action.payload.quantity,
          };
        } else {
          return {
            ...state,
            items: [action.payload, ...state.items],
            total:
              state.total +
              action.payload.product.product_price * action.payload.quantity,
          };
        }
      }
      break;
    case REMOVE_ITEM:
      {
        let currentItem = state.items.find(
          (item) => item.id == action.payload.id
        );
        return {
          ...state,
          items: state.items.filter((item) => item.id !== action.payload.id),
          total:
            state.total -
            currentItem.product.product_price * currentItem.quantity,
        };
      }
      break;
    case CHANGE_COUNT: {
      let { type, id } = action.payload;
      let currentItem = state.items.find((item) => item.id == id);
      let fiteredItems = state.items.filter((item) => item.id != id);
      if (type === "increment") {
        return{
            ...state,
        items : [
          { ...currentItem, quantity: currentItem.quantity + 1 },
          ...fiteredItems,
        ],
        total : state.total + currentItem.product.product_price * 1
    }
      } else if (type === "decrement") {
        return {
            ...state,
        items :
          currentItem.quantity <= 1
            ? [{ ...currentItem, qunatity: 1 }, ...fiteredItems]
            : [
                { ...currentItem, quantity: currentItem.quantity - 1 },
                ...fiteredItems,
              ],
        total : currentItem.quantity <= 1
            ? state.total
            : state.total - currentItem.product.product_price * 1
      }
    }
    }
    case SET_DELIVERY: return {
        ...state,
        deliveryFee: action.payload,
    }
    case EMPTY_CART: return {
        ...state,
        items: [],
        total: 0,
        deliveryFee:0,
    }
    case SET_CART: return {
        ...state,
        items: action.payload.items,
        total: action.payload.cartTotal,
        deliveryFee:action.payload.deliveryFee,
    }
    default:
      return state;
  }
};

export default orderReducer;