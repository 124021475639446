import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { fetchProducts } from "../api/products.api";
import AppLayout from "../components/Layout/AppLayout";
import AuthLayout from "../components/Layout/AuthLayout";
import CreateProductModal from "../components/Modals/CreateProductModal";
import DeleteProductModal from "../components/Modals/DeleteProductModal";
import UpdateProductModal from "../components/Modals/UpdateProductModal";
import ViewProductModal from "../components/Modals/ViewProductModal";
import { displayAmount, makeTitleCase, showAlert } from "../utils/helpers";

function AllProducts() {
  const [products, setProducts] = useState([]);
  const [isGettingProducts, setIsGettingProducts] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showViewModal, setShowViewModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)

  const getProducts = async () => {
    try {
      setIsGettingProducts(true)
      const resp = await fetchProducts();
      console.log(resp.value);
      setIsGettingProducts(false)
      if (!resp.status) {
        return showAlert({
          title: "Error!",
          msg: resp.errors[0].msg,
          icon: "error",
          btnText: "Close",
        });
      }
      setProducts(resp.value);
    } catch (err) {
      setIsGettingProducts(false)
      console.log(err);
      return showAlert({
        title: "Error!",
        msg: "Catch Error",
        icon: "error",
        btnText: "Close",
      });
    }
  };

  const handleClose = (type) => {
    switch(type){
      case "create": getProducts(); setShowCreateModal(false);
      break;
      case "view": setShowViewModal(false);setSelectedProduct(null);
      break;
      case "edit":getProducts();setShowEditModal(false); setSelectedProduct(null);  
      break;
      case "delete":getProducts();setShowDeleteModal(false); setSelectedProduct(null);  
      break;
      default:return;
    }
  }

  const handleEdit = (item) => {
    setSelectedProduct(item);
    setShowEditModal(true)
  }

  const handleDelete = (item) => {
    setSelectedProduct(item);
    setShowDeleteModal(true)
  }

  const handleView = (item) => {
    setSelectedProduct(item);
    setShowViewModal(true)
  }
  useEffect(() => {
    getProducts();

    return () => {};
  }, []);

  return (
    <AuthLayout>
      <AppLayout>
        <div className="container-fluid">
          {/* Page Heading */}
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Products</h1>
            <button type="button" onClick={()=>setShowCreateModal(true)} className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Create new Product</button>

          </div>

          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">
               All Products
              </h6>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellSpacing={0}
                >
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th>Item</th>
                      <th>Price</th>
                      <th>Available</th>
                      <th>Category</th>
                      <th>Promo</th>
                      <th>tags</th>
                      <th>Status</th>
                      <th>Created</th>
                      <th>Last modified</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th>S/N</th>
                      <th>Item</th>
                      <th>Price</th>
                      <th>Available</th>
                      <th>Category</th>
                      <th>Promo</th>
                      <th>tags</th>
                      <th>Status</th>
                      <th>Created</th>
                      <th>Last modified</th>
                      <th>Action</th>
                    </tr>
                  </tfoot>
                  <tbody>

                  { isGettingProducts && <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                        <span>Loading...</span>
                    </div> }

                    {products &&
                      products.length > 0 &&
                      products.map((item, index) => (
                        <tr
                          key={`${Math.floor(Math.random() * 10000)}-${index}`}
                        >
                          <td>{index + 1}</td>
                          <td>
                              
                           <div  onClick={()=>handleView(item)} style={{display:"flex", alignItems:"center", cursor:"pointer"}}> 
                           <div style={{ 
                                width: 50,
                                height: 50,
                                borderRadius: 10,
                                marginRight: 15,
                                backgroundImage: `url(${item.image_url})`,
                                backgroundRepeat:"no-repeat",
                                backgroundSize:"contain",
                                backgroundPosition:"center"
                                }}></div>
                            {makeTitleCase(item.name)}
                            </div>
                          </td>
                          <td>
                            {item.is_promo && item.sale_price !== null ? (
                              <>
                                <span style={{textDecorationLine:'line-through'}}>{displayAmount(item.product_price)}</span>
                                <span> {displayAmount(item.sale_price)}</span>
                              </>
                            ) : (
                              displayAmount(item.product_price)
                            )}
                          </td>
                          <td>{item.available 
                          ? <span style={{color:"green"}}>On sale</span>
                          : <span style={{color:"red"}}>Unavailable</span>}</td>
                          <td>{item.category}</td>
                          <td>{item.promo? "yes":"no"}</td>
                          <td>{item.tags.toString()}</td>
                          <td>{item.status}</td>
                          <td>{new Date(item.created).toDateString()}</td>
                          <td>{new Date(item.updated).toDateString()}</td>
                          <td style={{display:"flex", aligItems:"center",justifyContent:"space-between"}}>
                            <Button size="sm" onClick={()=>handleView(item)} variant="outline-primary"><i className="fas fa-eye"></i></Button>
                            <Button size="sm"  onClick={()=>handleEdit(item)} variant="outline-warning"><i className="fas fa-pencil-alt"></i></Button>
                            <Button size="sm" onClick={()=>handleDelete(item)} variant="outline-danger"><i className="fas fa-trash-alt"></i></Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
     
<CreateProductModal show={showCreateModal} close={()=>handleClose("create")}/>
{selectedProduct !== null && <ViewProductModal product={selectedProduct}  show={showViewModal}  close={()=>handleClose("view")} /> }
{selectedProduct !== null && <UpdateProductModal product={selectedProduct} show={showEditModal}  close={()=>handleClose("edit")}/> }
{selectedProduct !== null && <DeleteProductModal product={selectedProduct} show={showDeleteModal}  close={()=>handleClose("delete")}/> }
      </AppLayout>
    </AuthLayout>
  );
}

export default AllProducts;
