import { createStore,  compose, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import orderReducer from './reducers/orderReducer';
import outletReducer from './reducers/outletReducer';
import userReducer from './reducers/userReducer';

const middleware = [thunk]
const rootReducer = combineReducers({ 
    user: userReducer ,
    outlet: outletReducer,
    order: orderReducer
});


const mainConfigureStore = () => {
return createStore(
    rootReducer,
    {},
    compose(
        applyMiddleware(...middleware),
        window._REDUX_DEVTOOLS_EXTENSION_
          ? window._REDUX_DEVTOOLS_EXTENSION_()
          : f => f
      )

    );
}
export default mainConfigureStore;