import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { initializeOrder, modifyOrder } from "../../../api/gogen.api";
import { getLocations } from "../../../api/orders.api";
import { setDeliveryFee } from "../../../store/actions/orderAction";
import { displayAmount, makeTitleCase, showAlert } from "../../../utils/helpers";

const NewOrderModal = ({
  show,
  close,
  items,
  total,
  setDelivery,
  deliveryFee,
  order,
}) => {
    let emptyState = {
        outlet: null,
      }
    const initialState = order?  {
        name: order?.customer?.name,
        email: order?.customer?.email,
        phone: order?.customer?.phone,
        delivery: order?.deliveryMethod,
        selectedArea: order?.deliveryArea,
        description: order?.note,
        selectedOutlet: order?.outlet,
        address: order.address,
        outlet:  order?.outlet?.uid
    }:emptyState
   
  const [outlets, setOutlets] = useState([]);
  const [formdata, setFormdata] = useState(initialState);

  useQuery(["outlets"], getLocations, {
    onSuccess: (data) => {
      setOutlets(data);
    },
  });

  const { mutate, isLoading: isSendingOrder } = useMutation(order? modifyOrder :initializeOrder, {
    onSuccess: (data) => {
            if(data.status){
                showAlert({
                    title: "Sent!",
                    msg: `Order has been sent to ${formdata?.selectedOutlet?.name}`,
                    icon: "success",
                    btnText: "Close",
                  });
                  setFormdata(emptyState);
                  close()

            }
    },
  });

  const handleChange = (field, value) => {
    if (field === "outlet") {
      setFormdata((prev) => ({
        ...prev,
        [field]: value,
        selectedOutlet: outlets.find((val) => val.uid === value),
      }));
    } else if (field === "selectedArea") {
      console.log(value);
      let area = formdata?.selectedOutlet?.delivering_to.find(
        (val) => val.name === value
      );
      console.log(area);
      setFormdata((prev) => ({
        ...prev,
        selectedArea: area,
      }));
      setDelivery(area.amount);
    } else {
      setFormdata((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  const handleSubmit = async () => {
    // console.log(formdata, items, total);
    let {
      name,
      email,
      phone,
      delivery,
      selectedArea,
      description,
      selectedOutlet,
      address,
    } = formdata;
    const payload = {
        id: order?order._id:null,
      customer: { name, email, phone },
      address,
      deliveryMethod: delivery,
      deliveryArea: selectedArea,
      note: description,
      cartTotal: total,
      items,
      outlet: selectedOutlet,
      deliveryFee,
    };
    await mutate(payload);
  };
  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      size={"md"}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Total: {displayAmount(total + deliveryFee, 2)}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
        //   onSubmit={handleSubmit}
        >
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formSelect">
                <Form.Label>Select Outlet</Form.Label>
                <Form.Select
                  aria-label="Order Outlet"
                  onChange={(e) => handleChange("outlet", e.target.value)}
                  value={formdata.outlet}
                >
                  <option selected disabled value={null}>
                    Choose
                  </option>
                  {outlets.map((item, index) => (
                    <option
                      key={`${Math.floor(Math.random() * 10000)}-${index}`}
                      value={item.uid}
                    >
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Customer Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter the name of the customer"
              onChange={(e) => handleChange("name", e.target.value)}
              value={formdata.name}
            />
            {/* <Form.Text className="text-muted">
    We'll never share your email with anyone else.
  </Form.Text> */}
          </Form.Group>

          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder=""
                  onChange={(e) => handleChange("email", e.target.value)}
                  value={formdata.email}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formPhone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="tel"
                  onChange={(e) => handleChange("phone", e.target.value)}
                  value={formdata.phone}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formDelivery">
                <Form.Label>Delivery Method</Form.Label>
                <Form.Select
                  aria-label="Delivery method"
                  onChange={(e) => handleChange("delivery", e.target.value)}
                  value={formdata.delivery}
                  disabled={formdata.outlet == null}
                >
                  <option selected disabled>
                    Choose
                  </option>
                  <option value="pickup">Pick Up</option>
                  <option value="delivery">Home/Office Delivery</option>
                </Form.Select>
                {formdata.outlet == null && (
                  <Form.Text className="text-danger">
                    Choose an outlet before selecting delivery method
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>

          {formdata.delivery === "delivery" && (
            <>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formDelivery">
                    <Form.Label>Area </Form.Label>
                    <Form.Select
                      aria-label="Selected Area"
                      onChange={(e) =>
                        handleChange("selectedArea", e.target.value)
                      }
                      value={formdata?.selectedArea?.name}
                    >
                      <option selected disabled>
                        Choose
                      </option>
                      {formdata?.selectedOutlet?.delivering_to?.map((loc) => (
                        <option value={loc.name}>{loc.name}</option>
                      ))}
                    </Form.Select>
                    {formdata?.selectedArea && (
                      <Form.Text>
                        {" "}
                        Delivery Fee: {displayAmount(deliveryFee)}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formDelivery">
                    <Form.Label>Address </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      placeholder=""
                      onChange={(e) => handleChange("address", e.target.value)}
                      value={formdata?.address}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}

          <Form.Group className="mb-3" controlId="formDescription">
            <Form.Label>Additional Note</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="additional note for this order"
              onChange={(e) => handleChange("description", e.target.value)}
              value={formdata.description}
            />
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          onClick={handleSubmit}
          type="submit"
          variant="primary"
          //   disabled={isLoading}
        >
          {isSendingOrder?"Sending...":"Send order to outlet"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  items: state.order.items,
  total: state.order.total,
  deliveryFee: state.order.deliveryFee,
});

const mapDispatchToProps = (dispatch) => ({
  setDelivery: (amount) => {
    dispatch(setDeliveryFee(amount));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(NewOrderModal);
