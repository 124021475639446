import React, { useEffect, useState } from "react";
import DashCard from "../components/DashCard";
import AppLayout from "../components/Layout/AppLayout";
import AuthLayout from "../components/Layout/AuthLayout";
import { displayAmount } from "../utils/helpers";
import { getMessaging, getToken } from "firebase/messaging";
import { storeNotifToken } from "../api/app.api";
import { connect } from "react-redux";


function Dashboard({outlet}) {
  const [isSavingToken, setIsSavingToken] = useState(false)
  const [name, setName] = useState("")
  const messaging = getMessaging();
  const VAPIDKEY = "BJqB5jzuV_MklnwVr4d0oqtHJb4C88vjofCaM6IwmQXH1Nh3vtNBIzS2p-Dgno8Snng-L8eninZY_tWed3oBE4c"

  const getMessagingToken = async () => {
    if(isSavingToken) return;
    try{
      setIsSavingToken(true)
      const currentToken = await getToken(messaging, { vapidKey: VAPIDKEY });
      if (currentToken) {
          console.log(currentToken)
          //send to server
          const resp = await storeNotifToken({
            token: currentToken,
            outlet: outlet.uid
          })
          setIsSavingToken(false);

      } else {
        setIsSavingToken(false)
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
        // ...
      }
    }
    catch(err){
      setIsSavingToken(false)
      console.log('An error occurred while retrieving token. ', err)
    }  
};
  useEffect(() => {
  if(outlet && !isSavingToken){
    getMessagingToken()
    setName(outlet.name)
  }
  }, [outlet])
  
  return (
    <AuthLayout>
      <AppLayout>
        <div className="container-fluid">
          {/* Page Heading */}
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Welcome {name}</h1>
            {/* <select className="select">
                <option value="today">Today</option> 
                <option value="7">7 days</option> 
                <option value="30">30 days</option> 
                <option value="custom">Custom</option> 
            </select> */}
          </div>
          
          {/* Content Row */}
          {/* <div className="row">
            <DashCard type="primary" title="Total Sales" value={displayAmount(3000,2)} icon="money-bill-wave"  />
            <DashCard type="success" title="Total Orders" value={15} icon="money-bill-wave"  />
            <DashCard type="info" title="Total Items Sold" value={20} icon="hamburger"  />
          </div> */}


          {/* <div className="row">
            <div className="col-xl-8 col-lg-7">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">Recent Orders</h6>
                  <div className="dropdown no-arrow">
                    <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400" />
                    </a>
                    <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in" aria-labelledby="dropdownMenuLink">
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="chart-area">
                    <canvas id="myAreaChart" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">Top Selling Products</h6>
                  <div className="dropdown no-arrow">
                    <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400" />
                    </a>
                    <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in" aria-labelledby="dropdownMenuLink">
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="chart-pie pt-4 pb-2">
                    <canvas id="myPieChart" />
                  </div>
                  <div className="mt-4 text-center small">
                  </div>
                </div>
              </div>
            </div>
          </div> */}


        </div>
      </AppLayout>
    </AuthLayout>
  );
}

const mapStateToProps = state => ({
  outlet: state.outlet.details
})
export default connect(mapStateToProps)(Dashboard);
