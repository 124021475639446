import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchOutlet } from '../../api/outlet.api';
import { saveOutlet } from '../../store/actions/outletAction';
import { showAlert } from '../../utils/helpers';

function AuthLayout({children, rx_saveOutlet}) {
  let history = useNavigate();
    //make api call
    const getOutletByToken = async () => {
        try{
            const resp = await fetchOutlet()
            if(!resp.status){
              history("/logout")
            //   return showAlert({
            //   title: "Error!",
            //   msg: "Token has expired, Kindly logout and login again.",
            //   icon: "error",
            //   btnText: "Close",
            // });
          }
            rx_saveOutlet(resp.value)

        }
        catch(err){
            console.log(err);
            return showAlert({
              title: "Error!",
              msg: "Catch Error",
              icon: "error",
              btnText: "Close",
            });
        }
    }

    //set outlet to store

    useEffect(() => {
      getOutletByToken()
    
      return () => {
        
      }
    }, [])
    
  return children
}

const mapDispatchToProps = dispatch => ({
    rx_saveOutlet : data => {
        dispatch(saveOutlet(data))
    }
})
export default connect(null, mapDispatchToProps)(AuthLayout);