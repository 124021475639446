import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import {
  fetchProductCategories,
  updateSingleProduct,
} from "../../api/products.api";
import { displayAmount, makeTitleCase, showAlert } from "../../utils/helpers";

function UpdateProductModal({ show, close, product }) {
  const [categories, setCategories] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const initialState = {
    name: product.name,
    description: product.description,
    category: product.category,
    categoryId: product.category_uid,
    price: product.product_price,
    discountPrice: product.sale_price,
    available: product.available,
    variable: product.variable,
    varieties: product.varieties,
    image: product.image_url,
  };
  const emptyState = {
    name: "",
    description: "",
    category: null,
    categoryId: null,
    price: 0,
    discountPrice: 0,
    available: "on",
    variable: false,
    varieties: [],
    image: null,
  };
  const [formdata, setFormdata] = useState(initialState);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      let payload = new FormData();
      payload.append("uid", product.uid);
      payload.append("name", formdata.name);
      payload.append("description", formdata.description);
      payload.append("category", formdata.category);
      payload.append("category_uid", formdata.categoryId);
      payload.append("price", formdata.price);
      payload.append("sale_price", formdata.discountPrice);
      payload.append("available", formdata.available ? "on" : false);
      payload.append("variable", formdata.variable);
      payload.append("varieties", formdata.varieties);
      payload.append("image", formdata.image);
      //make API request
      const resp = await updateSingleProduct(payload);
      setIsLoading(false);
      if (!resp.status) {
        return showAlert({
          title: "Error!",
          msg:
            typeof resp.errors[0].msg == "string"
              ? resp.errors[0].msg
              : resp.errors[0].msg.message,
          icon: "error",
          btnText: "Close",
        });
      }
      close();
      setFormdata(emptyState);
      setImagePath("");
      return showAlert({
        title: "Product Updated!",
        msg: `${makeTitleCase(formdata.name)} for ${displayAmount(
          formdata.discountPrice > 0 && formdata.discountPrice < formdata.price
            ? formdata.discountPrice
            : formdata.price
        )} has been updated successfully`,
        icon: "success",
        btnText: "Close",
      });
    } catch (err) {
      console.log(err);
      setIsLoading(false)
      return showAlert({
        title: "Error!",
        msg: "Catch Error",
        icon: "error",
        btnText: "Close",
      });
    }
  };
  const getProductCategories = async () => {
    try {
      const resp = await fetchProductCategories();
      if (!resp.status)
        return showAlert({
          title: "Error!",
          msg:
            typeof resp.errors[0].msg == "string"
              ? resp.errors[0].msg
              : resp.errors[0].msg.message,
          icon: "error",
          btnText: "Close",
        });
      setCategories(resp.value);
    } catch (err) {
      console.log(err);
      return showAlert({
        title: "Error!",
        msg: "Catch Error",
        icon: "error",
        btnText: "Close",
      });
    }
  };

  const handleChange = (field, value) => {
    if (field === "image") {
      displayImage(value);
    }
    if (field === "category") {
      let catObj = categories.find((item) => item.uid === value);
      return setFormdata((prevState) => ({
        ...prevState,
        [field]: catObj.name,
        categoryId: value,
      }));
    }
    if (field === "available") {
      return  setFormdata((prevState) => ({
            ...prevState,
            [field]: value.checked,
          }));
    }
    setFormdata((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const displayImage = (val) => {
    var reader = new FileReader();
    reader.onload = function () {
      setImagePath(reader.result);
    };
    reader.readAsDataURL(val);
  };

  useEffect(() => {
    getProductCategories();
    return () => {};
  }, []);
  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      size={"lg"}
    >
      <Modal.Header closeButton>
        <Modal.Title>Update Product</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter a name for the Product"
              onChange={(e) => handleChange("name", e.target.value)}
              value={formdata.name}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="a short description about the product"
              onChange={(e) => handleChange("description", e.target.value)}
              value={formdata.description}
            />
          </Form.Group>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formSelect">
                <Form.Label>Category</Form.Label>
                <Form.Select
                  aria-label="Product Category"
                  onChange={(e) => handleChange("category", e.target.value)}
                  value={formdata.categoryId}
                >
                  {categories.map((item, index) => (
                    <option
                      key={`${Math.floor(Math.random() * 10000)}-${index}`}
                      value={item.uid}
                    >
                      {makeTitleCase(item.name)}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formSelect">
                <Form.Label>Subcategory</Form.Label>
                <Form.Select disabled aria-label="Product Subcategory">
                  <option>Select</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formPrice">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  placeholder=""
                  onChange={(e) => handleChange("price", e.target.value)}
                  value={formdata.price}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formDiscount">
                <Form.Label>Discounted Price</Form.Label>
                <Form.Control
                  type="number"
                  onChange={(e) =>
                    handleChange("discountPrice", e.target.value)
                  }
                  value={formdata.discountPrice}
                />
                <Form.Text className="text-muted">
                  Enter an amount if you are running a promo/discount, otherwise
                  leave blank
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Check
                type="checkbox"
                id="available-checkbox"
                label="Available on sale"
                checked={
                  formdata.available === "on" || formdata.available === true
                }
                onChange={(e) => handleChange("available", e.target)}
              />
            </Col>
            <Col>
              <Form.Check
                type="checkbox"
                id="variable-checkbox"
                label="Variable Product"
                // checked={
                //   formdata.variable === "on" || formdata.variable === true
                // }
                onChange={(e) => handleChange("variable", e.target.value)}
              />
            </Col>
          </Row>

          <Row>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label  style={{
                    borderStyle: "dotted",
                    borderWidth: 3,
                    borderRadius: 20,
                    height: 300,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundImage: `url(${
                      imagePath && imagePath != "" ? imagePath : formdata.image
                    })`,
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                  }}>
                  {formdata.image === null ? (
                    <>
                      <i className="fas fa-upload"></i>
                      <span style={{ marginLeft: 5 }}>
                        {" "}
                        Click to upload a product image
                      </span>
                    </>
                  ) : null}
              </Form.Label>
              <Form.Control
                type="file"
                hidden
                onChange={(e) => handleChange("image", e.target.files[0])}
              />
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Close
        </Button>
        <Button
          onClick={handleSubmit}
          type="submit"
          variant="primary"
          disabled={isLoading}
        >
          {isLoading ? "Updating..." : "Update Product"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UpdateProductModal;
