import React from "react";
import { connect } from "react-redux";
import Footer from "../Footer";
import Sidebar from "../Sidebar";
import { Topbar } from "../Topbar";

function AppLayout({ children, outlet }) {
  return (
    <div id="wrapper">
      <Sidebar outlet={outlet.details} />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Topbar outlet={outlet.details} />
          {children}
        </div>
        <Footer />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  outlet: state.outlet,
});
export default connect(mapStateToProps)(AppLayout);
