import { CHANGE_AUTH_STATUS } from '../actions/actionTypes';

const initialState = {
    info: null,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        // case SAVE_USER_TO_STORE:
        //     return {
        //         ...state,
        //         info: action.payload
        //     };
        default:
            return state;
    }
}

export default userReducer;