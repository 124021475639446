import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import Login from "./screens/Login";
import Dashboard from "./screens/Dashboard";
import Logout from "./screens/Logout";
import ProtectedRoute from "./components/ProtectedRoute";
import mainConfigureStore from "./store/configureStore";
import AllProducts from "./screens/AllProducts";
import SingleProduct from "./screens/SingleProduct";
import 'bootstrap/dist/css/bootstrap.min.css';
import AllOrders from "./screens/AllOrders";
import firebase from "./utils/firebase"
import NewOrder from "./screens/GoGen/NewOrder";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import CustomersOrders from "./screens/GoGen/CustomersOrders";
import CustomersOutletOrders from "./screens/GoGen/CustomersOutletOrders";
import AllOutletOrders from "./screens/AllOutletOrders";
import 'moment-timezone';

// Create a client
const queryClient = new QueryClient()
const store = mainConfigureStore();

const App = () => {
  // firebase()
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/products"
            element={
              <ProtectedRoute>
                <AllProducts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders"
            element={
              <ProtectedRoute>
                <AllOrders />
              </ProtectedRoute>
            }
          />
          <Route
            path="/staff-orders"
            element={
              <ProtectedRoute>
                <AllOutletOrders />
              </ProtectedRoute>
            }
          />
          <Route
            path="/customer-orders"
            element={
              <ProtectedRoute>
                <CustomersOrders />
              </ProtectedRoute>
            }
          />
          <Route
            path="/customer-outlet-orders"
            element={
              <ProtectedRoute>
                <CustomersOutletOrders />
              </ProtectedRoute>
            }
          />
          <Route
            path="/product/:pid"
            element={
              <ProtectedRoute>
                <SingleProduct />
              </ProtectedRoute>
            }
          />
          <Route
            path="/create/product"
            element={
              <ProtectedRoute>
                <SingleProduct />
              </ProtectedRoute>
            }
          />


          {/* ################################################## */}

          <Route
            path="/new-order"
            element={
              <ProtectedRoute>
                <NewOrder />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
