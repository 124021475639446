import React from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import { displayAmount, makeTitleCase } from '../../utils/helpers'

function ViewProductModal({show, close, product}) {
  return (
    <Modal
    show={show}
    onHide={close}
    keyboard={false}
    size={"lg"}
  >
 <Modal.Header closeButton>
        <Modal.Title>{makeTitleCase(product.name)}</Modal.Title>
      </Modal.Header>
           
      <Modal.Body>
      <Row>
                <Col sm={4}>
                    <div style={{backgroundImage:`url(${product.image_url})`, width:200, height:200, borderRaduis:20, backgroundSize:"contain", backgroundPosition:"center", backgroundRepeat:"no-repeat"}}></div>
                </Col>
                <Col>
                    <div>
                        <h3>
                        {product.is_promo && product.sale_price !== null ? (
                              <>
                                <span style={{textDecorationLine:'line-through', color:"#ff000066"}}>{displayAmount(product.product_price)}</span>
                                <span style={{fontWeight:"bold"}}> {displayAmount(product.sale_price)}</span>
                              </>
                            ) : (
                              displayAmount(product.product_price)
                            )}
                        </h3>
                    </div>
                    <p>
                        {product.description}
                    </p>
                    <div>
                    {     product.available 
                          ? <span style={{color:"green"}}>On sale</span>
                          : <span style={{color:"red"}}>Unavailable</span>
                    }
                    </div>
                    <div>Category: {product.category}</div>
                    <div>Status: {product.status}</div>
                    <div>Created on: {new Date(product.created).toDateString()}</div>
                </Col>
            </Row>
      </Modal.Body>
</Modal>
  )
}

export default ViewProductModal