import React, {useState} from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { deleteProduct } from "../../api/products.api";
import { displayAmount, makeTitleCase, showAlert } from "../../utils/helpers";

function DeleteProductModal({ show, close, product }) {
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async () => {
    try {
        setIsLoading(true)
      const resp = await deleteProduct(product);
      setIsLoading(false)
      if (!resp.status) {
        return showAlert({
          title: "Error!",
          msg:
            typeof resp.errors[0].msg == "string"
              ? resp.errors[0].msg
              : resp.errors[0].msg.message,
          icon: "error",
          btnText: "Close",
        });
      }
      close();
      return showAlert({
        title: "Delete Successful",
        msg: `${makeTitleCase(product.name)} has been deleted successfully`,
        icon: "success",
        btnText: "Close",
      });
    } catch (err) {
      console.log(err);
      setIsLoading(false)
      return showAlert({
        title: "Error!",
        msg: "Catch Error",
        icon: "error",
        btnText: "Close",
      });
    }
  };

  return (
    <Modal show={show} onHide={close} keyboard={false} size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure you want to delete?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={4}>
            <div
              style={{
                backgroundImage: `url(${product.image_url})`,
                width: 200,
                height: 200,
                borderRaduis: 20,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
          </Col>
          <Col>
            <div>
              <h3>
                {product.is_promo && product.sale_price !== null ? (
                  <>
                    <span
                      style={{
                        textDecorationLine: "line-through",
                        color: "#ff000066",
                      }}
                    >
                      {displayAmount(product.product_price)}
                    </span>
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      {displayAmount(product.sale_price)}
                    </span>
                  </>
                ) : (
                  displayAmount(product.product_price)
                )}
              </h3>
            </div>
            <p>{product.description}</p>
            <div>
              {product.available ? (
                <span style={{ color: "green" }}>On sale</span>
              ) : (
                <span style={{ color: "red" }}>Unavailable</span>
              )}
            </div>
            <div>Category: {product.category}</div>
            <div>Status: {product.status}</div>
            <div>Created on: {new Date(product.created).toDateString()}</div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          type="submit"
          variant="danger"
          disabled={isLoading}
        >
          {isLoading ? "Deleting..." : "Yes Delete it!"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteProductModal;
