import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { fetchOrders } from "../../api/gogen.api";
import AppLayout from "../../components/Layout/AppLayout";
import AuthLayout from "../../components/Layout/AuthLayout";
import ViewOrderModal from "../../components/Modals/GoGenModals/ViewOrderModal";
import {
  displayAmount,
  makeTitleCase,
  parseTimeDate,
} from "../../utils/helpers";

const CustomersOrders = () => {
  const [orders, setOrders] = useState([]);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  let { isLoading: isGettingOrders, refetch } = useQuery(["orders"], fetchOrders, {
    onSuccess: (data) => {
      if (data?.status) setOrders(data?.value);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const handleView = (order) => {
    setSelectedProduct(order);
    setShowViewModal(true);
  };

  const handleClose = (type) => {
    refetch();
    switch (type) {
      case "view":
        setShowViewModal(false);
        setSelectedProduct(null);
        break;
      default:
        return;
    }

  };

  return (
    <AuthLayout>
      <AppLayout>
        <div className="container-fluid">
          {/* Page Heading */}
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Customers Orders</h1>
          </div>

          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">All Orders</h6>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellSpacing={0}
                >
                  <thead>
                    <tr>
                      <th>Order#</th>
                      <th>Name</th>
                      {/* <th>Email</th>
                    <th>Phone</th> */}
                      <th>Items</th>
                      <th>Amount</th>
                      <th>Delivery Method</th>
                      {/* <th>Payment Status</th> */}
                      <th>Order Status</th>
                      <th>Created</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th>Order#</th>
                      <th>Name</th>
                      {/* <th>Email</th>
                    <th>Phone</th> */}
                      <th>Items</th>
                      <th>Amount</th>
                      <th>Delivery Method</th>
                      {/* <th>Payment Status</th> */}
                      <th>Order Status</th>
                      <th>Created</th>
                      <th>Action</th>
                    </tr>
                  </tfoot>
                  <tbody>
                    {isGettingOrders && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span>Loading...</span>
                      </div>
                    )}

                    {!isGettingOrders && orders.length > 0 && (
                      <>
                        {orders.map((order, index) => (
                          <tr
                            key={`${Math.floor(
                              Math.random() * 10000
                            )}-${index}`}
                          >
                            <td>{order.orderNumber}</td>
                            <td>
                              {order.customer.name
                                ? makeTitleCase(order.customer.name)
                                : ""}
                            </td>
                            {/* <td>{order.customer.email?(order.customer.email):null}</td>
                              <td>{order.customer.phone?order.customer.phone:null}</td> */}
                            <td>
                              {order.items.length}{" "}
                              {order.items.length > 1 ? "items" : "item"}
                            </td>
                            <td>
                              <strong>{displayAmount(order.sumTotal)}</strong>
                            </td>
                            {/* <td>{order.deliveryType ===  "tomorrow" ? "Next Day": "Instant"}</td> */}
                            <td>{makeTitleCase(order.deliveryMethod)}</td>
                            {/* <td>{order.deliveryMethod == "delivry"?`${order.address} ${order.deliveryArea.name}`:null}</td> */}
                            {/* <td>{order.paymentMethod  === "now" ? "Paid Instantly" : "Credit Payment"}</td> */}
                            {/* <td>
                            <Badge
                              bg={
                                order.paymentStatus === "paid"  ? "success" : "warning"
                              }
                            >
                              {makeTitleCase(order.paymentStatus)}
                            </Badge>
                          </td> */}
                            <td>
                            <Badge bg={ order.orderStatus === "placed"  ? "warning" : order.orderStatus === "prepared"? "primary":"success" }
                            >{makeTitleCase(order.orderStatus)} </Badge></td>
                            <td>
                              {new Date(order.created).toDateString()}{" "}
                              {parseTimeDate(order.created)}
                            </td>
                            {/* <td>{new Date(order.updated).toDateString()} {parseTimeDate(order.updated)}</td> */}
                            <td
                              style={{
                                display: "flex",
                                aligItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Button
                                size="sm"
                                  onClick={() => handleView(order)}
                                variant="outline-primary"
                              >
                                <i className="fas fa-eye"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {selectedProduct !== null && (
        <ViewOrderModal
          order={selectedProduct}
          show={showViewModal}
          close={() => handleClose("view")}
        />
      )}
      </AppLayout>
    </AuthLayout>
  );
};

export default CustomersOrders;
