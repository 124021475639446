import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Form, Row } from "react-bootstrap";
import { Minus, Plus, Search, Send, Trash } from "react-feather";
import { connect } from "react-redux";
import { fetchProducts } from "../../api/products.api";
import AppLayout from "../../components/Layout/AppLayout";
import AuthLayout from "../../components/Layout/AuthLayout";
import NewOrderModal from "../../components/Modals/GoGenModals/NewOrderModal";
import { addItem, emptyCart, setCart, setProducts } from "../../store/actions/orderAction";
import { displayAmount, makeTitleCase } from "../../utils/helpers";
import ItemSingle from "./ItemSingle";
import SingleCartItem from "./SingleCartItem";
import { useSearchParams } from "react-router-dom";
import { getOrderDetailsById } from "../../api/gogen.api";

const NewOrder = ({ saveProducts, products, cartItems, cartTotal, clearCart, setToCart }) => {
  const [showAll, setShowAll] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [completeOrder, setCompleteOrder] = useState(false)
  const [availableProducts, setAvailableProducts] = useState(products);
  const [searchParams, setSearchParams] = useSearchParams();
  const queryId = searchParams.get("oid");
  const [orderDetails, setOrderDetails] = useState({})
  const { data, isLoading, isSuccess, error } = useQuery(
    ["products"],
    fetchProducts,
    {
      onSuccess: (data) => {
        if (data?.status) saveProducts(data?.value);
      },
    }
  );

  const {
    isLoading: isLoadingOrderCart,
    isError: hasOrderCartError,
    refetch: refetchOrderCart,
  } = useQuery(["orderCart", queryId], getOrderDetailsById, {
    onSuccess: data=>{
        if(data.status){
          setOrderDetails(data.value)
          setToCart(data.value)
          console.log(data.value)
        }
    },
    enabled: false,
  });

  const startSettlement = () => {
    setCompleteOrder(true)
  }

  useEffect(() => {
    // if (showAll) 
    setAvailableProducts(products);
  }, [showAll, products]);

  const handleChange = async (e) => {
    let query = e.target.value.toLowerCase();
    setSearchQuery(query);
   
  };

  const handleCloseOrder = () => {
    setCompleteOrder(false)
    clearCart()

  }

  useEffect(() => {
    let prods = products.filter((val) =>
    val.name.toLowerCase().includes(searchQuery) || val.category.toLowerCase().includes(searchQuery) || val.product_price == searchQuery
  );
  setAvailableProducts(prods);
  }, [searchQuery])

  useEffect(() => {
    if (queryId) {
      refetchOrderCart();
    }
  }, [queryId]);
  

  return (
    <AuthLayout>
      <AppLayout>
        <div className="container-fluid">
          {/* Page Heading */}
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">New Order</h1>
          </div>

          <Row>
            <Col sm={8}>
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <Form>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalEmail"
                    >
                      <Col sm={10}>
                        <Form.Control
                          onChange={handleChange}
                          type="search"
                          placeholder="Search by name, category or amount"
                          value={searchQuery}
                        />
                      </Col>
                      {/* <Col sm={1}>
                        <button type="submit" className="btn btn-inline btn-sm">
                          <Search />
                        </button>
                      </Col> */}
                      <Col sm={2}>
                        <Button
                          size="sm"
                          variant="outline-danger"
                          onClick={() => setSearchQuery("")}
                        >
                          Clear
                        </Button>
                      </Col>
                    </Form.Group>
                  </Form>
                </div>
                <div
                  className="card-body"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  {availableProducts?.map((item, index) => (
                    <ItemSingle key={`gdgd-${index}`} product={item} />
                  ))}
                </div>
              </div>
            </Col>

            <Col sm={4}>
              <div className="card shadow mb-4" style={{ minHeight: 450 }}>
                <div className="card-header py-3">
                  <span>Cart Items</span>
                  <Badge style={{ marginLeft: 5 }} bg="danger">
                    {cartItems?.length}
                  </Badge>
                </div>
                <div className="card-body">
                  <div style={{ overflow: "scroll" }}>
                    <table className="table table-hover table-stripped">
                      <tbody>
                        {cartItems?.length > 0 &&
                          cartItems.map((orderItem, index) => (
                            <SingleCartItem
                              key={`hdb-${index}`}
                              orderItem={orderItem}
                              index={index}
                            />
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="footer p-15">
                    <div className="amount-tab mb-3" style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                      <strong>Total</strong>
                      <strong>{displayAmount(cartTotal, 2)}</strong>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-success btn-md btn-block mt-10"
                      onClick={startSettlement}
                    >
                      {queryId?"Update Order":"Complete Order"} 
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {completeOrder && <NewOrderModal order={queryId?orderDetails:null} show={completeOrder} close={handleCloseOrder}/>}
      </AppLayout>
    </AuthLayout>
  );
};

const mapStateToProps = (state) => ({
  products: state.order.allProducts,
  cartItems: state.order.items,
  cartTotal: state.order.total
});

const mapDispatchToProps = (dispatch) => ({
  saveProducts: (data) => {
    dispatch(setProducts(data));
  },
  clearCart: () => {
    dispatch(emptyCart())
  },
  setToCart : data => {
    dispatch(setCart(data));
}
});


export default connect(mapStateToProps, mapDispatchToProps)(NewOrder);
