import axios from "axios";
import { setHeader } from "../utils/helpers";


export const fetchProducts = async () => {
    try{
        let header = await setHeader();
const res = await axios.get(`/admin/outlet/products`, header)
    return res.data
    }
    catch(err){
        return {
            error:true,
            msg:err
        }
    }
}

export const fetchSingleProduct = async uid => {
    try{
        let header = await setHeader();
const res = await axios.get(`/admin/outlet/product/${uid}`, header)
    return res.data
    }
    catch(err){
        return {
            error:true,
            msg:err
        }
    }
}

export const fetchProductCategories = async () => {
    try{
const res = await axios.get(`/admin/outlet/categories`)
    return res.data
    }
    catch(err){
        return {
            error:true,
            msg:err
        }
    }
}


export const createSingleProduct = async data => {
    try{
        let header = await setHeader();
const res = await axios.post(`/admin/outlet/product/create`, data, header)
    return res.data
    }
    catch(err){
        return {
            error:true,
            msg:err
        }
    }
}

export const updateSingleProduct = async data => {
    try{
        let header = await setHeader();
const res = await axios.post(`/admin/outlet/product/update`, data, header)
    return res.data
    }
    catch(err){
        return {
            error:true,
            msg:err
        }
    }
}


export const deleteProduct = async data => {
    try{
        let header = await setHeader();
const res = await axios.post(`/admin/outlet/product/delete`, data, header)
    return res.data
    }
    catch(err){
        return {
            error:true,
            msg:err
        }
    }
}