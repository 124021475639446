import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import {
  convertHMS,
  displayAmount,
  makeTitleCase,
  parseTimeDate,
} from "../../../utils/helpers";

const ViewTrendModal = ({ show, close, order }) => {
  let timestamp =
    new Date(order?.timestamps?.prepared).getTime() -
    new Date(order?.timestamps?.created).getTime();
  // convertHMS
  return (
    <Modal show={show} onHide={close} keyboard={false} size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title>#{order.orderNumber}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Order date: {new Date(order.created).toDateString()}</p>
        <Row>
          <Col>
            <p>
              {" "}
              Order Created @:{" "}
              <strong>{parseTimeDate(order?.timestamps?.created)} </strong>
              <span>
                {new Date(order?.timestamps?.created).toDateString()}
              </span>
            </p>
            {(order?.orderStatus == "prepared" ||
              order?.orderStatus == "transit" ||
              order?.orderStatus == "completed") &&
              order?.timestamps?.prepared && (
                <p>
                  {" "}
                  Prepared by Outlet @:{" "}
                  <strong>{parseTimeDate(order?.timestamps?.prepared)} </strong>
                  <span>
                    {new Date(order?.timestamps?.prepared).toDateString()}
                  </span>
                </p>
              )}

            {(order?.orderStatus == "transit" ||
              order?.orderStatus == "completed") &&
              order?.timestamps?.transit && (
                <p>
                  {" "}
                  On transit @:{" "}
                  <strong>{parseTimeDate(order?.timestamps?.transit)} </strong>
                  <span>
                    {new Date(order?.timestamps?.transit).toDateString()}
                  </span>
                </p>
              )}

            {order?.orderStatus == "completed" &&
              order?.timestamps?.completed &&(
                <p>
                  {" "}
                  Completed @:{" "}
                  <strong>
                    {parseTimeDate(order?.timestamps?.completed)}{" "}
                  </strong>
                  <span>
                    {new Date(
                      order?.timestamps?.completed
                    ).toDateString()}
                  </span>
                </p>
              )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ViewTrendModal;
