import { SAVE_OUTLET } from '../actions/actionTypes';

const initialState = {
    details: null,
};

const outletReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_OUTLET:
            return {
                ...state,
                details: action.payload
            };
        default:
            return state;
    }
}

export default outletReducer;