
import axios from "axios";
import { setHeader } from "../utils/helpers";

export const getLocations = async () => {
    try{
        const resp = await axios.get('/admin/locations')
        return resp.data
    }
    catch(err){
        return {
            error:true,
            msg:err
        }
    }
}
export const fetchOrders = async () => {
    try{
        let header = await setHeader();
        const resp = await axios.get('/admin/outlet/orders', header)
        return resp.data
    }
    catch(err){
        return {
            error:true,
            msg:err
        }
    }
}
export const fetchAllOrders = async () => {
    try{
        const resp = await axios.get('/admin/all/orders')
        return resp.data
    }
    catch(err){
        return {
            error:true,
            msg:err
        }
    }
}


export const updateOrder = async data => {
    try{
        let header = await setHeader();
        const resp = await axios.post('/admin/outlet/order/update', data, header)
        return resp.data
    }
    catch(err){
        return {
            error:true,
            msg:err
        }
    }
}