import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { cookieGet } from "../utils/cookie";
import { NAIRA_SIGN } from "../configs/app";

const MySwal = withReactContent(Swal);

export const showAlert = ({ title, msg, icon, btnText }) =>
  MySwal.fire({
    title,
    text: msg,
    icon,
    confirmButtonText: btnText,
  });

export const setHeader = async () => {
  let token = await cookieGet("token");
  return {
    headers: {
      token,
    },
  };
};

export const thousand = (amount) => {
  // convert number to string
  amount = String(amount);
  // return amount string with commas after every three characters
  return amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const displayAmount = (
  amount, pad, sign = NAIRA_SIGN,
) => {
  // return number string with commas after every three characters
  return `${sign}${thousand(Number(amount).toFixed(pad)).trim()}`;
};

export const makeTitleCase = str => {
  if (!str || str.length < 1) return "";
  return str
    .toLowerCase()
    .split(" ")
    .map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}


export function parseTimeDate(date) {
  const dateSplit = date.split('T');
  if (dateSplit.length < 2) {
    return date;
  }
  const time = dateSplit[1].split(':');
  let ampm = 'am';
  let hour = Number(time[0]);
  let minute = Number(time[1]);
  if (hour - 12 >= 0) {
    hour = hour == 12 ? hour : hour - 12;
    ampm = 'pm';
  }
  return padNumber(hour) + ':' + padNumber(minute) + ampm;
}


export const padNumber = (amount, maxLength = 2) => {
  const num = String(amount);
  if (num.length >= maxLength) {
    return num;
  }
  // add zero to number
  amount = `0${amount.toString().trim()}`;
  if (amount.length < maxLength) {
    return padNumber(amount, maxLength);
  }
  // return amount
  return amount;
};


export function convertHMS(value) {
  const sec = parseInt(value, 10); // convert value to number if it's string
  let hours   = Math.floor(sec / 3600); // get hours
  let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
  let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
  // add 0 if value < 10; Example: 2 => 02
  if (hours   < 10) {hours   = "0"+hours;}
  if (minutes < 10) {minutes = "0"+minutes;}
  if (seconds < 10) {seconds = "0"+seconds;}
  return hours+':'+minutes+':'+seconds; // Return is HH : MM : SS
}