import axios from "axios";
export const loginApi = async data => {
    try{
const res = await axios.post(`/admin/outlet/login`, data)
    return res.data
    }
    catch(err){
        return {
            error:true,
            msg:err
        }
    }
}