import axios from "axios";
import { setHeader } from "../utils/helpers";

export const initializeOrder = async (data) => {
  try {
    const res = await axios.post(`/genesis/initialize`, data);
    return res.data;
  } catch (err) {
    return {
      error: true,
      msg: err,
    };
  }
};
export const modifyOrder = async (data) => {
  try {
    const res = await axios.post(`/genesis/modify-order`, data);
    return res.data;
  } catch (err) {
    return {
      error: true,
      msg: err,
    };
  }
};

export const fetchOrders = async () => {
  try {
    let header = await setHeader();
    const resp = await axios.get("/genesis/outlet/orders", header);
    return resp.data;
  } catch (err) {
    return {
      error: true,
      msg: err,
    };
  }
};
export const fetchAllOrders = async () => {
  try {
    let header = await setHeader();
    const resp = await axios.get("/genesis/outlet/allorders", header);
    return resp.data;
  } catch (err) {
    return {
      error: true,
      msg: err,
    };
  }
};

export const updateOrder = async (data) => {
  let { type, id } = data;
  try {
    let url =
      type == "prepared"
        ? `/genesis/order/prepared/${id}`
        : type == "transit"
        ? `/genesis/order/transit/${id}`
        : `/genesis/order/completed/${id}`;
    const resp = await axios.get(url);
    return resp.data;
  } catch (err) {
    return {
      error: true,
      msg: err,
    };
  }
};

export const getOrderDetailsById = async ({ queryKey }) => {
  try {
    const res = await axios.get(`/genesis/order/details/${queryKey[1]}`);
    return res.data;
  } catch (err) {
    return {
      error: true,
      msg: err,
    };
  }
};
