import { SET_PRODUCTS , ADD_ITEM, REMOVE_ITEM, CHANGE_COUNT, SET_DELIVERY, EMPTY_CART, SET_CART} from './actionTypes';

export const setProducts = data => {
    return {
        type: SET_PRODUCTS,
        payload: data,
    }
}
export const addItem = data => {
    return {
        type: ADD_ITEM,
        payload: data,
    }
}
export const removeItem = data => {
    return {
        type: REMOVE_ITEM,
        payload: data,
    }
}
export const changeCount = (type, id) => {
    return {
        type: CHANGE_COUNT,
        payload: {type,id},
    }
}

export const setDeliveryFee = amount => {
    return {
        type: SET_DELIVERY,
        payload: amount,
    }
}
export const setCart = data => {
    return {
        type: SET_CART,
        payload: data,
    }
}

export const emptyCart = () => {
    return {
        type: EMPTY_CART,
    }
}