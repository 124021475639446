import React, {useState} from "react";
import {
  Modal,
  Badge,
  Row,
  Col,
  Card,
  ListGroup,
  Button,
  Table,
  Form,
} from "react-bootstrap";
import { updateOrder } from "../../api/orders.api";
import {
  makeTitleCase,
  parseTimeDate,
  displayAmount,
  showAlert,
} from "../../utils/helpers";
import moment from "moment"

function ViewOrderModal({ show, close, order }) {
  const stages = ['Pending Payment', 'Payment Successful', 'processing', 'ready', 'arrived', 'completed', 'feedback']
  const [newStatus, setNewStatus] = useState(order.orderStatus)
  const [isLoading, setIsLoading] = useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault();
    try{
      setIsLoading(true)
      const resp = await updateOrder({status:newStatus, orderId:order.oid})
      setIsLoading(false)
      if(!resp.status) return showAlert({
        title: "Error!",
        msg:
          typeof resp.errors[0].msg == "string"
            ? resp.errors[0].msg
            : resp.errors[0].msg.message,
        icon: "error",
        btnText: "Close",
      });
      close(newStatus !== order.orderStatus? true: false);
      return showAlert({
        title: "Order Updated!",
        msg: `The status of the order has been updated successfully`,
        icon: "success",
        btnText: "Close",
      });

    }
    catch(err){
      console.log(err)
      setIsLoading(false)
      return showAlert({
        title: "Error!",
        msg: "Catch Error",
        icon: "error",
        btnText: "Close",
      });
    }
  }
  return (
    <Modal show={show} onHide={()=>close(newStatus !== order.orderStatus? true: false)} keyboard={false} size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title>Order #{order.orderNumber}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row style={{ marginTop: 10, marginBottom: 10 }}>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span> Created on:</span>
            <span style={{ fontWeight: "bold" }}>
              {/* {new Date(order.created).toDateString()}{" "}
              {parseTimeDate(order.created)} */}
               {moment(order?.created).format(
                              "MMM Do YYYY, h:mm A"
                            )}
            </span>
          </Col>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span> Payment Method:</span>
            <span style={{ fontWeight: "bold" }}>
            {order.paymentMethod === "credit"?makeTitleCase(order.paymentMethod):order.paymentMethod === "now"?"Cash":""}
            </span>
          </Col>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>Payment Status:</span>
            <span style={{ fontWeight: "bold" }}>
              <Badge
                size="lg"
                bg={order.paymentStatus === "paid" ? "success" : "warning"}
              >
                {makeTitleCase(order.paymentStatus)}
              </Badge>
            </span>
          </Col>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>Order Status:</span>
            <span style={{ fontWeight: "bold" }}>{order.orderStatus}</span>
          </Col>
        </Row>

        <Row style={{ marginTop: 10, marginBottom: 10 }}>
          <Col>
            {" "}
            <Card>
              <Card.Body>
                <Card.Title>Customer Information</Card.Title>
                <Card.Text>
                  <Row>
                    <Col>
                      <span>Name: </span>
                      <span style={{ fontWeight: "bold" }}>
                        {`${makeTitleCase(
                          order.user.first_name
                        )} ${makeTitleCase(order.user.last_name)}`}
                      </span>
                    </Col>
                  </Row>

                  {order.user.email && (
                    <Row>
                      <Col>
                        <span>Email: </span>
                        <span style={{ fontWeight: "bold" }}>
                          {order.user.email}
                        </span>
                      </Col>
                    </Row>
                  )}
                  {order.user.phone && (
                    <Row>
                      <Col>
                        <span>Phone: </span>
                        <span style={{ fontWeight: "bold" }}>
                          {order.user.phone}
                        </span>
                      </Col>{" "}
                    </Row>
                  )}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            {" "}
            <Card>
              <Card.Body>
                <Card.Title>Billing Information</Card.Title>
                <Card.Text>
                  <Row>
                    <Col>
                      <span>Name: </span>
                      <span style={{ fontWeight: "bold" }}>
                        {order.customer.name}
                      </span>
                    </Col>
                  </Row>

                  {order.customer.email && (
                    <Row>
                      <Col>
                        <span>Email: </span>
                        <span style={{ fontWeight: "bold" }}>
                          {order.customer.email}
                        </span>
                      </Col>
                    </Row>
                  )}
                  {order.customer.phone && (
                    <Row>
                      <Col>
                        <span>Phone: </span>
                        <span style={{ fontWeight: "bold" }}>
                          {order.customer.phone}
                        </span>
                      </Col>{" "}
                    </Row>
                  )}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row  style={{ marginTop: 10, marginBottom: 10 }}>
          <Col>
            <span>Delivery Method: </span>
            <span style={{ fontWeight: "bold" }}>
              {makeTitleCase(order.deliveryMethod)}
            </span>
          </Col>
          <Col>
            <span>Delivery Type: </span>
            <span style={{ fontWeight: "bold" }}>
              {makeTitleCase(order.deliveryType)}
            </span>
          </Col>
        </Row>

        {order.deliveryMethod === "delivery" && (
          <Row  style={{ marginTop: 10, marginBottom: 10 }}>
            <Card.Title>Address</Card.Title>
            <Card.Text>
              {order.address} {order.deliveryArea.name}
            </Card.Text>
          </Row>
        )}
        {order.note.length > 0 && (
          <Row  style={{ marginTop: 10, marginBottom: 10 }}>
            <Card.Title>Order Note</Card.Title>
            <Card.Text>
              {order.note}
            </Card.Text>
          </Row>
        )}

        <Row  style={{ marginTop: 10, marginBottom: 10 }}>
          <Col>
            <Table bordered hover>
              <thead>
                <tr>
                  <th>Items</th>
                  <th>Amount</th>
                  <th>Quantity</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {order.items.map((item) => (
                  <tr>
                    <td style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          width: 40,
                          height: 40,
                          borderRadius: 5,
                          marginRight: 5,
                          backgroundImage: `url(${item.product.image_url})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "center",
                        }}
                      ></div>
                      {makeTitleCase(item.product.name)}
                    </td>
                    <td>
                      {item.product.is_promo && item.product.sale_price !== null
                        ? displayAmount(item.product.sale_price, 2)
                        : displayAmount(item.product.product_price, 2)}
                    </td>
                    <td>{item.quantity}</td>
                    <td>{displayAmount(item.amount, 2)}</td>
                  </tr>
                ))}
                <tr>
                  {" "}
                  <td></td> <td></td>
                  <td style={{ textAlign: "right" }}>Sub Total:</td>
                  <td>{displayAmount(order.cartSum, 2)}</td>
                </tr>
                {(order.deliveryMethod === "delivery" ||
                  order.deliveryFee > 0) && (
                  <tr>
                    {" "}
                    <td></td> <td></td>
                    <td style={{ textAlign: "right" }}>Delivery Fee:</td>
                    <td>{displayAmount(order.deliveryFee, 2)}</td>
                  </tr>
                )}
                {order.cartPromo && order.cartPromo > 0 && (
                  <tr>
                    {" "}
                    <td></td> <td></td>
                    <td style={{ textAlign: "right" }}>Staff Discount: </td>
                    <td>{displayAmount(order.cartPromo, 2)}</td>
                  </tr>
                )}
                <tr>
                  {" "}
                  <td></td> <td></td>
                  <td style={{ fontWeight: "bold", textAlign: "right" }}>
                    Total:
                  </td>
                  <td style={{ fontWeight: "bold" }}>
                    {displayAmount(order.sumTotal, 2)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>


        <Form onSubmit={handleSubmit}>
  <fieldset>
    <Form.Group className="mb-3">
      <Form.Label htmlFor="select">Update Order Status</Form.Label>
      <Form.Select id="select" value={newStatus} onChange={(e)=>setNewStatus(e.target.value)}>
        {
          stages.map(item=><option value={item}>{makeTitleCase(item)}</option>)
        }
      </Form.Select>
    </Form.Group>
    <Button disabled={isLoading} type="submit"  onClick={handleSubmit}>{isLoading?"Updating...":"Update"}</Button>
  </fieldset>
</Form>
      </Modal.Body>
    </Modal>
  );
}

export default ViewOrderModal;
