import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import {
  createSingleProduct,
  fetchProductCategories,
} from "../../api/products.api";
import { displayAmount, makeTitleCase, showAlert } from "../../utils/helpers";

function CreateProductModal({ show, close }) {
  const [categories, setCategories] = useState([]);
  const [imagePath, setImagePath] = useState("")
  const [isLoading, setIsLoading] = useState(false)
const initialState = {
    name: "",
    description: "",
    category: null,
    categoryId: null,
    price: 0,
    discountPrice: 0,
    available: "on",
    variable: false,
    varieties: [],
    image: null,
  }
  const [formdata, setFormdata] = useState(initialState);

  const handleChange = (field, value) => {
    if (field === "image") {
      displayImage(value)
    }
    if (field === "category") {
        let catObj = categories.find((item) => item.uid === value);
         return  setFormdata((prevState) => ({
           ...prevState,
           [field]: catObj.name,
           categoryId: value
         }));
       }

    setFormdata((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleSubmit = async (e) => {
      try{
        e.preventDefault();
        setIsLoading(true)
        let payload = new FormData();
        payload.append("name", formdata.name);
        payload.append("description", formdata.description);
        payload.append("category", formdata.category);
        payload.append("category_uid", formdata.categoryId);
        payload.append("price", formdata.price);
        payload.append("sale_price", formdata.discountPrice);
        payload.append("available", formdata.available?"on":false);
        payload.append("variable", formdata.variable);
        payload.append("varieties", formdata.varieties);
        payload.append("image", formdata.image);
        //make API request
        const resp = await createSingleProduct(payload);
        setIsLoading(false)
        if (!resp.status){
          return showAlert({
            title: "Error!",
            msg:
              typeof resp.errors[0].msg == "string"
                ? resp.errors[0].msg
                : resp.errors[0].msg.message,
            icon: "error",
            btnText: "Close",
          });
        }
        close();
        setFormdata(initialState)
        setImagePath("")
        return showAlert({
          title: "Product Created!",
          msg: `${makeTitleCase(formdata.name)} for ${displayAmount(
            formdata.discountPrice > 0 && formdata.discountPrice < formdata.price ? formdata.discountPrice : formdata.price
          )} has been created successfully`,
          icon: "success",
          btnText: "Close",
        });
      }
      catch(err){
        console.log(err);
        setIsLoading(false)
        return showAlert({
          title: "Error!",
          msg: "Catch Error",
          icon: "error",
          btnText: "Close",
        });
      }
   
  };
  const getProductCategories = async () => {
    try {
      const resp = await fetchProductCategories();
      if (!resp.status)
        return showAlert({
          title: "Error!",
          msg:
            typeof resp.errors[0].msg == "string"
              ? resp.errors[0].msg
              : resp.errors[0].msg.message,
          icon: "error",
          btnText: "Close",
        });

      setCategories(resp.value);
    } catch (err) {
      console.log(err);
      return showAlert({
        title: "Error!",
        msg: "Catch Error",
        icon: "error",
        btnText: "Close",
      });
    }
  };

  const displayImage = (val) => {
        var reader = new FileReader();
        reader.onload = function(){
        setImagePath(reader.result)
        };
        reader.readAsDataURL(val);
  }

  useEffect(() => {
    getProductCategories();
    return () => {};
  }, []);

  useEffect(() => {
    if (categories.length > 0 && formdata.category === null)
      setFormdata({
        ...formdata,
        category: categories[0],
      });
  }, [categories]);

  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      size={"lg"}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create a new Product</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter a name for the Product"
              onChange={(e) => handleChange("name", e.target.value)}
              value={formdata.name}
            />
            {/* <Form.Text className="text-muted">
      We'll never share your email with anyone else.
    </Form.Text> */}
          </Form.Group>

          <Form.Group className="mb-3" controlId="formDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="a short description about the product"
              onChange={(e) => handleChange("description", e.target.value)}
              value={formdata.description}
            />
          </Form.Group>

          {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Check me out" />
          </Form.Group> */}

          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formSelect">
                <Form.Label>Category</Form.Label>
                <Form.Select
                  aria-label="Product Category"
                  onChange={(e) => handleChange("category", e.target.value)}
                  value={formdata.categoryId}
                >
                  {categories.map((item, index) => (
                    <option
                      key={`${Math.floor(Math.random() * 10000)}-${index}`}
                      value={item.uid}
                    >
                      {makeTitleCase(item.name)}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formSelect">
                <Form.Label>Subcategory</Form.Label>
                <Form.Select disabled aria-label="Product Subcategory">
                  <option>Select</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formPrice">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  placeholder=""
                  onChange={(e) => handleChange("price", e.target.value)}
                  value={formdata.price}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formDiscount">
                <Form.Label>Discounted Price</Form.Label>
                <Form.Control
                  type="number"
                  onChange={(e) =>
                    handleChange("discountPrice", e.target.value)
                  }
                  value={formdata.discountPrice}
                />
                <Form.Text className="text-muted">
                  Enter an amount if you are running a promo/discount, otherwise
                  leave blank
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Check
                type="checkbox"
                id="available-checkbox"
                label="Available on sale"
                checked={
                  formdata.available === "on" || formdata.available === true
                }
                onChange={(e) => handleChange("available", e.target.value)}
              />
            </Col>
            <Col>
              <Form.Check
                type="checkbox"
                id="variable-checkbox"
                label="Variable Product"
                // checked={
                //   formdata.variable === "on" || formdata.variable === true
                // }
                onChange={(e) => handleChange("variable", e.target.value)}
              />
            </Col>
          </Row>

          <Row>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label  style={{
                    // width: "auto",
                    borderStyle: "dotted",
                    borderWidth: 3,
                    borderRadius: 20,
                    height: 300,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundImage: `url(${imagePath})`,
                    backgroundPosition:"center",
                    backgroundSize:"contain",
                    backgroundRepeat:"no-repeat",
                  }}>                
                {
                    formdata.image === null
                    ?<>
                    <i className="fas fa-upload"></i>
                  <span style={{ marginLeft: 5 }}>
                    {" "}
                    Click to upload a product image
                  </span></>
                    :null
                }
                 </Form.Label>
              <Form.Control
                type="file"
                hidden
                onChange={(e) => handleChange("image", e.target.files[0])}
              />
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Close
        </Button>
        <Button onClick={handleSubmit} type="submit" variant="primary" disabled={isLoading}>
          {isLoading?"Creating...":"Create Product"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateProductModal;
