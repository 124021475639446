import React from 'react'
import { Minus, Plus, Trash } from 'react-feather'
import { connect } from 'react-redux'
import { changeCount, removeItem } from '../../store/actions/orderAction'
import { displayAmount, makeTitleCase } from '../../utils/helpers'

const SingleCartItem = ({orderItem, deleteCartItem, changeItemCount }) => {
    const deleteItem = order => {
        let conf = window.confirm("Are you sure you want to delete this item?");
        if(!conf) return;
        deleteCartItem(order)
    }
  return (
    <tr
                            style={{
                              paddingTop: 10,
                              paddingBottom: 10,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <td
                              className="text-danger"
                              style={{ cursor: "pointer" }}
                            >
                              <button
                                className="btn btn-sm btn-outline-danger"
                                onClick={() => deleteItem(orderItem)}
                              >
                                <Trash size={12} />
                              </button>
                            </td>

                            <td >
                              {makeTitleCase(orderItem.product.name)}
                            </td>
                            <td
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <button
                                // disabled={orderNumber && userInfo.role < 4}
                                onClick={() =>
                                    changeItemCount("decrement", orderItem.id)
                                }
                                className="btn btn-danger btn-sm"
                                style={{ cursor: "pointer" }}
                              >
                                <Minus size={12} />
                              </button>
                              <span style={{ marginLeft: 10, marginRight: 10 }}>
                                {orderItem.quantity}
                              </span>
                              <button className="btn btn-success btn-sm"
                                onClick={() => changeItemCount("increment", orderItem.id) }
                                style={{ cursor: "pointer" }}
                              >
                                <Plus size={12} />
                              </button>
                            </td>
                            <td style={{}}>
                              {displayAmount(orderItem.product.product_price, 2)}
                            </td>
                            <td style={{}}>
                              {displayAmount(
                                orderItem.product.product_price * orderItem.quantity,
                                2
                              )}
                            </td>
                          </tr>
  )
}


const mapDispatchToProps = dispatch => ({
    deleteCartItem: data => {
        dispatch(removeItem(data));
    },
    changeItemCount : (type, id) => {
        dispatch(changeCount(type, id))
    }
})
export default connect(null, mapDispatchToProps)(SingleCartItem)

