import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { addItem } from "../../store/actions/orderAction";
import { displayAmount, makeTitleCase } from "../../utils/helpers";

const ItemSingle = ({ product , saveToCart}) => {

    const addToCart = async () => {
        let payload = { product, quantity:1, id:product.uid }
        saveToCart(payload)
    }

  return (
    <Card className="mb-3" style={{ width: "10rem", cursor:"pointer"}} onClick={addToCart}>
      <Card.Img variant="top" src={product.img_url} />
      <Card.Body>
        <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
          <span>{makeTitleCase(product.name)}</span>
          <span style={{marginLeft:5}}><strong>{displayAmount(product.product_price)}</strong></span>
        </div>
      </Card.Body>
    </Card>
  );
};


const mapDispatchToProps = dispatch => ({
    saveToCart : data => {
        dispatch(addItem(data));
    }
})
export default connect(null, mapDispatchToProps)(ItemSingle);
