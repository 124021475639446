import React from "react";
import { Link } from "react-router-dom";

function Sidebar({ outlet }) {
  let isAgent = outlet?.username === "ecommerce" ? true : false;
  return (
    <div id="wrapper">
      {/* Sidebar */}
      <ul
        className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        id="accordionSidebar"
      >
        {/* Sidebar - Brand */}
        <Link
          className="sidebar-brand d-flex align-items-center justify-content-center"
          to="/dashboard"
        >
          <img
            src={require("../assets/images/qsr_top_logo.png")}
            className="img-responsive"
            style={{ width: 120 }}
          />
        </Link>

        {/* <hr className="sidebar-divider my-0" /> */}
        {/* Nav Item - Dashboard */}
        {/* <li className="nav-item">
          <Link className="nav-link" to="/dashboard">
            <i className="fas fa-fw fa-tachometer-alt" />
            <span>Dashboard</span>
          </Link>
        </li> */}

        {!isAgent && (
          <>
            <hr className="sidebar-divider" />
            <div className="sidebar-heading">Products</div>
            {/* Nav Item - Charts */}
            <li className="nav-item">
              <Link className="nav-link" to="/products">
                <i className="fas fa-fw fa-chart-area" />
                <span>All Products</span>
              </Link>
            </li>
          </>
        )}

        {!isAgent && (
          <>
            <hr className="sidebar-divider d-none d-md-block" />
            <div className="sidebar-heading">Go Premium Orders</div>
            <li className="nav-item">
              <Link className="nav-link" to="/orders">
                <i className="fas fa-fw fa-chart-area" />
                <span>Staff Orders</span>
              </Link>
            </li>
          </>
        )}

        {/* {!isAgent && <><hr className="sidebar-divider d-none d-md-block" />
        <div className="sidebar-heading">Go Genesis Orders</div>
        <li className="nav-item">
          <Link className="nav-link" to="/customer-orders">
            <i className="fas fa-fw fa-chart-area" />
            <span>Customers Orders</span>
          </Link>
        </li>
        </>} */}

          {isAgent && <>
            <li className="nav-item">
          <Link className="nav-link" to="/new-order">
            <i className="fas fa-fw fa-plus" />
            <span>New Order</span>
          </Link>
        </li>
          </>}

          {isAgent && (
          <>
            <hr className="sidebar-divider d-none d-md-block" />
            <div className="sidebar-heading">Go Premium Orders</div>
            <li className="nav-item">
              <Link className="nav-link" to="/staff-orders">
                <i className="fas fa-fw fa-chart-area" />
                <span>Staff Orders</span>
              </Link>
            </li>
          </>
        )}

          {/* {isAgent && <><hr className="sidebar-divider d-none d-md-block" />
        <div className="sidebar-heading">Go Genesis Orders</div>
        <li className="nav-item">
          <Link className="nav-link" to="/customer-outlet-orders">
            <i className="fas fa-fw fa-chart-area" />
            <span>Customers Orders</span>
          </Link>
        </li>
        </>} */}

<><hr className="sidebar-divider d-none d-md-block" />
        <li className="nav-item">
          <Link className="nav-link" to="/logout">
            <i className="fas fa-sign-out-alt" />
            <span>Logout</span>
          </Link>
        </li>
        </>




      </ul>
    </div>
  );
}

export default Sidebar;
