import React, { useEffect, useState } from "react";
import { Badge, Button, Form } from "react-bootstrap";
import { fetchOrders } from "../api/orders.api";
import AppLayout from "../components/Layout/AppLayout";
import AuthLayout from "../components/Layout/AuthLayout";
import ViewOrderModal from "../components/Modals/ViewOrderModal";
import {
  displayAmount,
  makeTitleCase,
  parseTimeDate,
  showAlert,
} from "../utils/helpers";
import moment from "moment";

function AllOrders() {
  const [orders, setOrders] = useState([]);
  const [isGettingOrders, setIsGettingOrders] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const getOrders = async () => {
    try {
      setIsGettingOrders(true);
      const resp = await fetchOrders();
      // console.log(resp.value);
      setIsGettingOrders(false);
      if (!resp.status) {
        return showAlert({
          title: "Error!",
          msg: resp.errors[0].msg,
          icon: "error",
          btnText: "Close",
        });
      }
      setOrders(resp.value);
    } catch (err) {
      // console.log(err);
      return showAlert({
        title: "Error!",
        msg: "Catch Error",
        icon: "error",
        btnText: "Close",
      });
    }
  };

  const handleClose = (type, changeStatus) => {
    if(changeStatus)getOrders();
    switch (type) {
      case "view":
        setShowViewModal(false);
        setSelectedProduct(null);
        // changeStatus ? getOrders() : true;
        break;
      default:
        return;
    }

  };

  const handleView = (order) => {
    setSelectedProduct(order);
    setShowViewModal(true);
  };

  useEffect(() => {
    getOrders();
    return () => {};
  }, []);

  return (
    <AuthLayout>
      <AppLayout>
        <div className="container-fluid">
          {/* Page Heading */}
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Orders</h1>
            {/* <Form.Select aria-label="Date Select">
              <option>Today</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select> */}
          </div>

          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">All Orders</h6>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellSpacing={0}
                >
                  <thead>
                    <tr>
                      <th>Order#</th>
                      <th>Name</th>
                      {/* <th>Email</th>
                      <th>Phone</th> */}
                      <th>Items</th>
                      <th>Amount</th>
                      <th>Delivery Method</th>
                      <th>Payment Status</th>
                      <th>Order Status</th>
                      <th>Created</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th>Order#</th>
                      <th>Name</th>
                      {/* <th>Email</th>
                      <th>Phone</th> */}
                      <th>Items</th>
                      <th>Amount</th>
                      <th>Delivery Method</th>
                      <th>Payment Status</th>
                      <th>Order Status</th>
                      <th>Created</th>
                      <th>Action</th>
                    </tr>
                  </tfoot>
                  <tbody>
                    {isGettingOrders && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span>Loading...</span>
                      </div>
                    )}

                    {!isGettingOrders && orders.length > 0 && (
                      <>
                        {orders.map((order, index) => (
                          <tr
                            key={`${Math.floor(
                              Math.random() * 10000
                            )}-${index}`}
                          >
                            <td>{order.orderNumber}</td>
                            <td>
                              {order.customer.name
                                ? makeTitleCase(order.customer.name)
                                : ""}
                            </td>
                            {/* <td>{order.customer.email?(order.customer.email):null}</td>
                                <td>{order.customer.phone?order.customer.phone:null}</td> */}
                            <td>
                              {order.items.length}{" "}
                              {order.items.length > 1 ? "items" : "item"}
                            </td>
                            <td>
                              <strong>{displayAmount(order.sumTotal)}</strong>
                            </td>
                            {/* <td>{order.deliveryType ===  "tomorrow" ? "Next Day": "Instant"}</td> */}
                            <td>{makeTitleCase(order.deliveryMethod)}</td>
                            {/* <td>{order.deliveryMethod == "delivry"?`${order.address} ${order.deliveryArea.name}`:null}</td> */}
                            {/* <td>{order.paymentMethod  === "now" ? "Paid Instantly" : "Credit Payment"}</td> */}
                            <td>
                              <Badge
                                bg={
                                  order.paymentStatus === "paid"
                                    ? "success"
                                    : "warning"
                                }
                              >
                                {makeTitleCase(order.paymentStatus)}
                              </Badge>
                            </td>
                            <td>{makeTitleCase(order.orderStatus)}</td>
                            <td>
                              {/* {new Date(order.created).toDateString()}{" "}
                              {parseTimeDate(order.created)} */}
                               {moment(order?.created).format(
                              "MMM Do YYYY, h:mm A"
                            )}
                            </td>
                            {/* <td>{new Date(order.updated).toDateString()} {parseTimeDate(order.updated)}</td> */}
                            <td
                              style={{
                                display: "flex",
                                aligItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Button
                                size="sm"
                                onClick={() => handleView(order)}
                                variant="outline-primary"
                              >
                                <i className="fa fa-eye"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {selectedProduct !== null && (
          <ViewOrderModal
            order={selectedProduct}
            show={showViewModal}
            close={(changeStatus) => handleClose("view", changeStatus)}
          />
        )}
      </AppLayout>
    </AuthLayout>
  );
}

export default AllOrders;
