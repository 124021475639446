import axios from "axios";
import { setHeader } from "../utils/helpers";


export const fetchOutlet = async () => {
    try{
        let header = await setHeader();
const res = await axios.get(`/admin/outlet/details`, header)
    return res.data
    }
    catch(err){
        return {
            error:true,
            msg:err
        }
    }
}
