import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { Modal, Badge, Row, Col, Card, Button, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { updateOrder } from "../../../api/gogen.api";
import {
  displayAmount,
  makeTitleCase,
  parseTimeDate,
  showAlert,
} from "../../../utils/helpers";

const ViewOrderModal = ({ show, close, order, outlet }) => {
  let isAgent = outlet?.details?.username == "ecommerce" ? true : false;
  const [newStatus, setNewStatus] = useState(order?.orderStatus);

  const { mutate, isLoading } = useMutation(updateOrder, {
    onSuccess: (data) => {
      if (data.status) {
        showAlert({
          title: "Updated!",
          msg: `Order status has been updated`,
          icon: "success",
          btnText: "Close",
        });
        close();
      }
    },
    onError: (err) => console.log(err),
  });
  const handleSubmit = async (type) => {
    let conf = window.confirm("Are you sure you want to update the status of this order?")
    if(!conf) return;
    setNewStatus(type);
    mutate({ type, id: order._id });
  };
  return (
    <Modal
      show={show}
      onHide={() => close(newStatus !== order?.orderStatus ? true : false)}
      keyboard={false}
      size={"lg"}
    >
      <Modal.Header closeButton>
        <Modal.Title>Order #{order?.orderNumber}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row style={{ marginTop: 10, marginBottom: 10 }}>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span> Created on:</span>
            <span style={{ fontWeight: "bold" }}>
              {new Date(order?.created).toDateString()}{" "}
              {parseTimeDate(order?.created)}
            </span>
          </Col>

          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>Order Status:</span>
            <span style={{ fontWeight: "bold" }}>
            <Badge bg={ order.orderStatus === "placed"  ? "warning" : order.orderStatus === "prepared"? "primary":"success" }>
              {makeTitleCase(order?.orderStatus)}
            </Badge>
            </span>
          </Col>
        </Row>

        <Row style={{ marginTop: 10, marginBottom: 10 }}>
          <Col>
            {" "}
            <Card>
              <Card.Body>
                <Card.Title>Customer Information</Card.Title>
                <Card.Text>
                  <Row>
                    <Col>
                      <span>Name: </span>
                      <span style={{ fontWeight: "bold" }}>
                        {order?.customer?.name}
                      </span>
                    </Col>
                  </Row>

                  {order?.customer?.email && (
                    <Row>
                      <Col>
                        <span>Email: </span>
                        <span style={{ fontWeight: "bold" }}>
                          {order?.customer?.email}
                        </span>
                      </Col>
                    </Row>
                  )}
                  {order?.customer?.phone && (
                    <Row>
                      <Col>
                        <span>Phone: </span>
                        <span style={{ fontWeight: "bold" }}>
                          {order?.customer?.phone}
                        </span>
                      </Col>{" "}
                    </Row>
                  )}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row style={{ marginTop: 10, marginBottom: 10 }}>
          <Col>
            <span>Delivery Method: </span>
            <span style={{ fontWeight: "bold" }}>
              {makeTitleCase(order?.deliveryMethod)}
            </span>
          </Col>
        </Row>

        {order?.deliveryMethod === "delivery" && (
          <Row style={{ marginTop: 10, marginBottom: 10 }}>
            <Card.Title>Address</Card.Title>
            <Card.Text>
              {order?.address} {order?.deliveryArea?.name}
            </Card.Text>
          </Row>
        )}
        {order?.note?.length > 0 && (
          <Row style={{ marginTop: 10, marginBottom: 10 }}>
            <Card.Title>Order Note</Card.Title>
            <Card.Text>{order?.note}</Card.Text>
          </Row>
        )}

        <Row style={{ marginTop: 10, marginBottom: 10 }}>
          <Col>
            <Table bordered hover>
              <thead>
                <tr>
                  <th>Items</th>
                  <th>Amount</th>
                  <th>Quantity</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {order?.items.map((item) => {
                  let unitPrice =
                    item.product.is_promo && item.product.sale_price !== null
                      ? item.product.sale_price
                      : item.product.product_price;
                  return (
                    <tr>
                      <td style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: 5,
                            marginRight: 5,
                            backgroundImage: `url(${item.product.image_url})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                          }}
                        ></div>
                        {makeTitleCase(item.product.name)}
                      </td>
                      <td>{displayAmount(unitPrice)}</td>
                      <td>{item.quantity}</td>
                      <td>{displayAmount(unitPrice * item.quantity, 2)}</td>
                    </tr>
                  );
                })}
                <tr>
                  {" "}
                  <td></td> <td></td>
                  <td style={{ textAlign: "right" }}>Sub Total:</td>
                  <td>{displayAmount(order?.cartTotal, 2)}</td>
                </tr>
                {(order?.deliveryMethod === "delivery" ||
                  order?.deliveryFee > 0) && (
                  <tr>
                    {" "}
                    <td></td> <td></td>
                    <td style={{ textAlign: "right" }}>Delivery Fee:</td>
                    <td>{displayAmount(order?.deliveryFee, 2)}</td>
                  </tr>
                )}

                <tr>
                  {" "}
                  <td></td> <td></td>
                  <td style={{ fontWeight: "bold", textAlign: "right" }}>
                    Total:
                  </td>
                  <td style={{ fontWeight: "bold" }}>
                    {displayAmount(order?.sumTotal, 2)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>

        <div className="text-center mt-5">
          {isAgent && (
            order.orderStatus === "prepared" || order.orderStatus === "transit" ? (
              <Button
              disabled={isLoading}
                className="btn btn-lg btn-success"
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit("completed");
                }}
                type="submit"
                //  disabled={isLoading}
              >
                {isLoading ? "Updating":"Mark order as completed"}
              </Button>
            ) : null
          )} 

          {!isAgent && order.orderStatus === "placed"  && (
                <Button
                disabled={isLoading}
                className="btn btn-lg btn-primary"
                onClick={(e) => {
                    e.preventDefault();
                    handleSubmit("prepared");
                }}
                type="submit"
                >
                {isLoading ? "Updating":"This order is now ready"}
                </Button>
          )}

          {!isAgent && order.orderStatus === "prepared" && order?.deliveryMethod === "delivery" && (
                <Button
                disabled={isLoading}
                className="btn btn-lg btn-info"
                onClick={(e) => {
                    e.preventDefault();
                    handleSubmit("transit");
                }}
                type="submit"
                >
                {isLoading ? "Updating":"This order is now on transit"}
                </Button>
          )}

        </div>
      </Modal.Body>
    </Modal>
  );
};


const mapStateToProps = (state) => ({
  outlet: state.outlet,
});
export default connect(mapStateToProps)(ViewOrderModal);
