import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginApi } from "../api/auth.api";
import Spinner from "../components/Spinner";
import { cookieGet, cookieStore } from "../utils/cookie";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { showAlert } from "../utils/helpers";

const MySwal = withReactContent(Swal);

function Login() {
  const history = useNavigate();
  const [formdata, setFormdata] = useState({
    username: "",
    password: "",
    isLoading: false,
    error: false,
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setFormdata((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      const loginResp = await loginApi(formdata);
      setFormdata((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      console.log(loginResp);
      if (!loginResp.status) {
        return showAlert({
          title: "Error!",
          msg:
            typeof loginResp.errors[0].msg == "string"
              ? loginResp.errors[0].msg
              : loginResp.errors[0].msg.message,
          icon: "error",
          btnText: "Close",
        });
      }
      //store to cookie
      cookieStore("token", loginResp.value.token);
      //redirect
      history("/dashboard");
    } catch (err) {
      console.log(err);
      return showAlert({
        title: "Error!",
        msg: "Catch Error",
        icon: "error",
        btnText: "Close",
      });
    }
  };

  const handleChange = (field, value) => {
    setFormdata((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const checkForCookie = async () => {
    const val = await cookieGet("token");
    if (val) {
      //redirect
      history("/dashboard");
    }
  };
  useEffect(() => {
    //check for cookie
    checkForCookie();
    return () => {};
  }, []);

  return (
    <div className="container">
      {/* Outer Row */}
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12 col-md-9">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              {/* Nested Row within Card Body */}
              <div className="row">
                <div className="col-lg-6 d-none d-lg-block bg-login-image" />
                <div className="col-lg-6">
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-4">Login</h1>
                    </div>
                    <form className="user" onSubmit={handleLogin}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-user"
                          id="exampleInputEmail"
                          placeholder="Username"
                          onChange={(e) =>
                            handleChange("username", e.target.value)
                          }
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control form-control-user"
                          id="exampleInputPassword"
                          placeholder="Password"
                          onChange={(e) =>
                            handleChange("password", e.target.value)
                          }
                        />
                      </div>

                      <button
                        className="btn btn-primary btn-user btn-block"
                        type="submit"
                      >
                        {!formdata.isLoading ? "Login" : <Spinner />}
                      </button>
                      <hr />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
