import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchSingleProduct } from "../api/products.api";
import AppLayout from "../components/Layout/AppLayout";
import AuthLayout from "../components/Layout/AuthLayout";
import { makeTitleCase } from "../utils/helpers";

function SingleProduct() {
  const { pid } = useParams();
  const [product, setProduct] = useState(null);
  const getProduct = async () => {
    try {
      const resp = await fetchSingleProduct(pid);
      console.log(resp);
      if (!resp.status) return;
      setProduct(resp.value);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getProduct();

    return () => {};
  }, []);

  return (
    <AuthLayout>
      <AppLayout>
        <div className="container-fluid">
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">{product? makeTitleCase(product.name):null}</h1>
            <Link to={`/product/edit/${pid}`}
              className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
            >
              <i className="fas fa-edit fa-sm text-white-50" /> Edit
            </Link>
          </div>




        </div>
      </AppLayout>
    </AuthLayout>
  );
}

export default SingleProduct;
